<template>
  <div>
    <b-row class="mt-2">
      <b-col md="8"
        ><FormSelect
          label="Banco"
          :ready="ready"
          v-model="model.bank_id"
          :selectOptions="bankList"
          :error="errors.includes('model.bank_id')"
      /></b-col>
      <b-col>
        <FormInput
          label="Agencia"
          :ready="ready"
          v-model="model.agency"
          :error="errors.includes('model.agency')"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col
        ><FormSelect
          label="Tipo de Conta"
          :ready="ready"
          v-model="model.account_type"
          :selectOptions="[
            {
              value: '1',
              text: 'C/C',
            },
            {
              value: '2',
              text: 'Poupança',
            },
          ]"
          :error="errors.includes('model.account_type')"
      /></b-col>
      <b-col
        ><FormInput
          label="Conta"
          :ready="ready"
          v-model="model.account"
          :error="errors.includes('model.account')"
      /></b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col
        ><FormInput
          label="Descrição"
          :ready="ready"
          v-model="model.description"
          :error="errors.includes('model.description')"
      /></b-col>
      <b-col>
        <FormInput
          label="Documento(CPF/CNPJ)"
          :disabled="firstRegister"
          :ready="ready"
          v-model="model.document"
          :error="errors.includes('model.document')"
          :mask="
            model.document.length > docSize
              ? '##.###.###/####-##'
              : '###.###.###-##'
          "
        />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end my-4">
      <b-button v-if="editing" variant="primary-custom" @click="update_account"
        >Salvar Alterações</b-button
      >
      <b-button v-else variant="primary-custom" @click="submit_new_account"
        >Criar nova conta</b-button
      >
    </div>
  </div>
</template>

<script>
import schemas from "../schemas/AddEditFormSchema.js";
export default {
  props: ["bankList"],
  data() {
    return {
      docSize: 14,
      editing: false,
      ready: false,
      firstRegister: false,
      required: [
        "model.bank_id",
        "model.agency",
        "model.account_type",
        "model.account",
        "model.description",
        "model.document",
      ],
      errors: [],
      model: {
        company_id: "",
        unity_id: this.$route.params.unityId,
        bank_id: "",
        description: "",
        account_type: "",
        agency: "",
        account: "",
        document: "",
        active: "1",
      },
      schemas: schemas({ bankList: this.bankList }),
    };
  },
  methods: {
    async submit_new_account() {
      this.check_required();
      if (this.errors.length == 0) {
        this.model.document = this.model.document.replace(/[^0-9]/g, "");
        const response = await this.$store.dispatch(
          "unities/save_bank_account",
          this.model
        );
        if (response) {
          this.$router.go(-1);
        }
      }
    },
    async update_account() {
      this.check_required();

      if (this.errors.length == 0) {
        this.model.document = this.model.document.replace(/[^0-9]/g, "");

        const response = await this.$store.dispatch(
          "unities/update_bank_account",
          this.model
        );
        if (response) {
          this.$router.go(-1);
        }
      }
    },
    async get_bank_accounts(unityId) {
      const result = await this.$store.dispatch("http_get", {
        url: `/unity/${unityId}/bank-account`,
      });
      if (result.data.length == 0) {
        this.firstRegister = true;
      }
    },
    async get_unity_document(unityId) {
      const result = await this.$store.dispatch("http_get", {
        url: `/unity/${unityId}`,
      });
      if (this.firstRegister) {
        this.docSize = 11
        this.model.document = result.data[0].cnpj;
      }
    },
  },
  async mounted() {
    console.log(this.$route)
    const accountId = this.$route.params.accountId;
    const unityId = this.$route.params.unityId;
    if (accountId) {
      const accountDetails = await this.$store.dispatch(
        "unities/get_bank_account_by_id",
        accountId
      );

      console.log(accountDetails);

      this.model = accountDetails;

      this.editing = true;
    }
    await this.get_bank_accounts(unityId);
    await this.get_unity_document(unityId);
    this.ready = true;
  },
};
</script>

<style></style>
